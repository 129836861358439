import React from 'react';

import cn from 'classnames';
import memoize from 'memoize-one';
import Select from 'react-select';

// import { isMobile } from '../../../utils/browser';
import Label from '../Label';

import style from './Select.module.scss';

export type OptionType = {
  label: string;
  value: string;
  selectInRange?: number[];
};

export interface ISelectInputProps {
  id?: string;
  name: string;
  icon?: JSX.Element;
  options: OptionType[];
  value?: string;
  label?: string;
  toolTip?: string;
  hasLabel?: boolean;
  defaultValue?: OptionType[];
  defaultInputValue?: string;
  isMulti?: boolean;
  helperClassName?: string;
  placeholder?: string;
  helperText?: string;
  closeMenuOnSelect?: boolean;
  autoFocus?: boolean;
  menuIsOpen?: boolean;
  className?: string;
  isSearchable?: boolean;
  borderRadius?: string;
  openMenuOnFocus?: boolean;
  defaultMenuIsOpen?: boolean;

  isOptionDisabled?: (option: OptionType) => boolean;

  onChange: (name: string, value: any) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;

  error?: string;
}

const memoizeValue = memoize(
  (options: OptionType[], value: string | undefined) => {
    return options.find((o: OptionType) => o.value === value);
  },
);

export default function SelectInput(props: ISelectInputProps) {
  const {
    name,
    options,
    onChange,
    onFocus,
    onBlur,
    isSearchable = true,
    openMenuOnFocus = true,
    hasLabel = true,
    label = '',
    toolTip,
    placeholder,
    borderRadius,
    helperClassName,
    helperText,
    value,
    defaultValue,
    className,
    error,
    ...restProps
  } = props;

  const customValue = memoizeValue(options, value);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.name = name;

    switch (e.type) {
      case 'focus':
        if (typeof onFocus === 'function') {
          onFocus(e);
        }
        break;
      case 'blur':
        if (typeof onBlur === 'function') {
          onBlur(e);
        }
        break;
      default:
        break;
    }
  };

  const handleChange = (selectedOption: any) => {
    onChange(name, selectedOption.value);
  };

  const handleChangeNative = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value } = e.target;

    onChange(name, value);
  };

  return (
    <div
      className={cn(className, style.select, {
        [style.error]: !!error,
      })}
    >
      {false ? (
        <div className={cn(style.mobileSelect, className)}>
          <select
            {...restProps}
            className={cn({ [style.hasValue]: customValue })}
            id={name}
            name={name}
            onChange={handleChangeNative}
            onFocus={handleFocus as any}
            onBlur={handleFocus as any}
            value={value}
          >
            <option
              className={style.optionPlaceholder}
              value=""
              hidden
            >
              {placeholder}
            </option>
            {options.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </select>
        </div>
      ) : (
        <div className={style.fullSelect}>
          {hasLabel && (
            <Label label={label} htmlFor={name} toolTip={toolTip} />
          )}
          <Select
            {...restProps}
            id={name}
            onBlur={onBlur}
            name={name}
            options={options}
            classNamePrefix="reactSelect"
            onChange={handleChange}
            blurInputOnSelect={false}
            menuPlacement="auto"
            isSearchable={isSearchable}
            value={customValue}
            defaultValue={defaultValue}
            placeholder={placeholder}
            openMenuOnFocus={openMenuOnFocus}
            indicatorSeparator={null}
          />
        </div>
      )}
    </div>
  );
}
